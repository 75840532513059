/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from "embla-carousel-autoplay";
import Link from "next/link";
import { AnimatePresence } from "framer-motion";
import Image from "next/legacy/image";
import NextImage from "next/image";
import ReactPlayer from "react-player";

const AUTOPLAY_INTERVAL = 7000;
let easing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
    animate: {
        transition: {
            staggerChildren: 0.05
        }
    }
};

const fade = {
    initial: {
        x: 160,
        opacity: 0,
        transition: { duration: 1, ease: easing }
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: easing
        }
    }
};
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 1, ease: easing }
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: easing
        }
    }
};

const variants = {
  active: {
    opacity: 1,
    background: "#7fffd4",
    x: "-50px",
    scale: 1.5,
    color: "#333"
  },
  inActive: {
    opacity: 1,
    background: "#f95c5c",
    x: "50px",
    scale: 1,
    color: "white"
  }
};

const getImportedLink = (link) =>{
    let linkId = link;
    let idExtractor = /\/d\/(.+?)(?:\/|#|\?|$)/;
    let result = idExtractor.exec(linkId);
    if (!result) {
        return link;
    }
    const finalLink = 'https://drive.google.com/uc?export=download&id='+ result[1];
    return finalLink;
}

const EmblaCarousel = ({ slides, nkey, classN, mobile, autoplayInterval = 7000 }) => {
    const autoplay = useRef(
        Autoplay(
            { delay: autoplayInterval, stopOnInteraction: true },
            (emblaRoot) => emblaRoot.parentElement
        )
    );

    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, loop: false }, [autoplay.current]);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    const scrollNext = useCallback(() => {
        if (!embla) return;
        embla.scrollNext();
        autoplay.current.reset();
        autoplay.current.stop();
    }, [embla]);

    const scrollPrev = useCallback(() => {
        if (!embla) return;
        embla.scrollPrev();
        autoplay.current.reset();
        autoplay.current.stop();
    }, [embla]);

    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    const onScroll = useCallback(() => {
        if (!embla) return;
        const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
        setScrollProgress(progress * 100);
    }, [embla, setScrollProgress]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        onScroll();
        embla.on("select", onSelect);
        embla.on("scroll", onScroll);
    }, [embla, onSelect, onScroll]);


    //
    // const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);
    //
    // const scrollNext = useCallback(() => {
    //     if (!embla) return;
    //     embla.scrollNext();
    //     stop();
    // }, [embla, stop]);
    //
    // const scrollPrev = useCallback(() => {
    //     if (!embla) return;
    //     embla.scrollPrev();
    //     stop();
    // }, [embla, stop]);
    //
    // const onSelect = useCallback(() => {
    //     if (!embla) return;
    //     setPrevBtnEnabled(embla.canScrollPrev());
    //     setNextBtnEnabled(embla.canScrollNext());
    // }, [embla]);
    //
    // const onScroll = useCallback(() => {
    //     if (!embla) return;
    //     const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    //     setScrollProgress(progress * 100);
    // }, [embla, setScrollProgress]);
    //
    // const scrollTo = useCallback((index) => {
    //     if (!embla) return;
    //     embla.scrollTo(index)
    // }, [embla]);

    // useEffect(() => {
    //     if (!embla) return;
    //     onSelect();
    //     onScroll();
    //     embla.on("select", onSelect);
    //     embla.on("pointerDown", stop);
    //     embla.on("scroll", onScroll);
    //     console.log(embla);
    // }, [embla, onSelect, stop, onScroll]);

    // useEffect(() => {
    //     play();
    // }, [play]);
  if(!slides) return;
  return (
      <>
        <div className="embla">
            <div className="embla__viewport" ref={viewportRef}>
                <AnimatePresence>
                    <div className="embla__container ">
                        {slides?.map((carousel, index) => {
                            let currentSlide =  embla?.selectedScrollSnap();
                            let checkSlide = (currentSlide == index) ? true : false;
                            return (
                                <div key={"carousel"+carousel.id+nkey} className="embla__slide">
                                    <div className={`embla__slide__inner `+classN}>
                                        <div className="relative w-full h-full flex justify-center align-middle items-center bg-gray-100">
                                            {mobile == 0 ? (
                                                <Fragment>
                                                    {carousel.desktop_video_src ? (
                                                        <ReactPlayer
                                                            className="w-full h-full"
                                                            url={getImportedLink(carousel.desktop_video_src)}
                                                            width="100%"
                                                            height="100%"
                                                            playing
                                                            loop
                                                            muted
                                                        />
                                                    ) : (
                                                        <NextImage
                                                            src={carousel.desktop_image_src ? getImportedLink(carousel.desktop_image_src) : '/images/no-image.png'}
                                                            alt={carousel.title}
                                                            fill
                                                        />
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    {carousel.mobile_video_src ? (
                                                        <ReactPlayer
                                                            className="w-full h-full"
                                                            url={getImportedLink(carousel.mobile_video_src)}
                                                            width="100%"
                                                            height="100%"
                                                            playing
                                                            loop
                                                            muted
                                                        />
                                                    ) : (
                                                        <Image
                                                            src={carousel.mobile_image_src ? getImportedLink(carousel.mobile_image_src) : '/images/no-image.png'}
                                                            alt={carousel.title}
                                                            layout="fill"
                                                            objectFit="cover"
                                                        />
                                                    )}
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="absolute top-0 left-0 w-full h-full justify-center grid grid-cols-1 z-10">
                                            <div className="flex flex-col justify-center relative w-full lg:px-28 md:px-24 sm:px-16 px-10">
                                                <span className={`${(carousel.text_color_title == 0) ? 'text-black' : 'text-white'} block md:text-7xl sm:text-6xl text-4xl font-bold  text-shadow-white`}>{carousel.title}</span>
                                                <div
                                                    className={`${(carousel.text_color_description == 0) ? 'text-black' : 'text-white'} mt-4 block md:text-xl sm:text-lg text-base text-justify font-light text-shadow-white`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: carousel.description,
                                                    }}></div>
                                                {carousel.link && (
                                                    <Link href={carousel.link}>
                                                        <button className="mb-3 pk-bg-pink hover:bg-red-300 text-lg text-white mt-1 py-2 px-3 rounded-full font-bold tracking-wider uppercase">
                                                            {carousel.button_name}
                                                        </button>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                        {/*<img
                                            className="embla__slide__img object-cover block opacity-100"
                                            src={carousel.image_url ? carousel.image_url : '/images/no-image.png'}
                                            alt={carousel.title}
                                                />*/}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </AnimatePresence>
                <div className="embla__progress -top-4">
                    <div
                        className="embla__progress__bar"
                        style={{ transform: `translateX(${scrollProgress}%)` }}
                    />
                </div>
            </div>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>

    </>
  );
};

export default EmblaCarousel;
